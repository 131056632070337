import React, { useState, useEffect, useRef, useImperativeHandle, forwardRef } from 'react';
import { INSGridRendererComponent } from 'nscomponentsreact';

interface RealTimeRendererProps {
  data?: any;
  dataField?: string;
  index?: number;
  colIndex?: number;
  row?: any;
}

export interface RealTimeRendererHandle {
  setData: (data: any, dataField: string, index: number, colIndex: number, row: any) => void;
  getElement: () => HTMLElement | null;
}

const RealTimeRenderer = forwardRef<RealTimeRendererHandle, RealTimeRendererProps>((props, ref) => {
  const [item, setItem] = useState<any>(null);
  const [value, setValue] = useState<number | null>(null);
  const [delta, setDelta] = useState<number | null>(null);
  const [deltaUp, setDeltaUp] = useState<boolean>(true);
  const [lastValue, setLastValue] = useState<number>(0);
  const [refreshCount, setRefreshCount] = useState<number>(0);
  const elementRef = useRef<HTMLDivElement | null>(null);

  // useImperativeHandle to expose methods
  useImperativeHandle(ref, () => ({
    setData: (data: any, dataField: string, index: number, colIndex: number, row: any) => {
      const newValue: number = data[dataField];
      let deltaChange: number | null = null;

      if (newValue !== lastValue) {
        if (typeof newValue === 'number' && typeof data.prevAsset === 'number') {
          deltaChange = newValue - data.prevAsset;
        }
        setLastValue(newValue);
        setItem(data);
        setValue(newValue);
        setDelta(Math.abs(deltaChange || 0));
        setDeltaUp((deltaChange || 0) >= 0);
        removeDelta();
      }
    },
    getElement: () => elementRef.current,
  }));

  // Helper function to remove delta after a timeout
  const removeDelta = () => {
    const currentRefreshCount = refreshCount + 1;
    setRefreshCount(currentRefreshCount);
    
    setTimeout(() => {
      if (currentRefreshCount === refreshCount) {
        setDelta(null);
      }
    }, 2000);
  };

  // Render JSX
  const style = { width: "100%" };
  const classIcon = `fa ${(delta == null) ? "" : (deltaUp ? "fa-arrow-up realTimeCellDeltaUp" : "fa-arrow-down realTimeCellDeltaDown")}`;
  const classRealTimeDelta = `realTimeCellDelta ${deltaUp ? "realTimeCellDeltaUp" : "realTimeCellDeltaDown"}`;
  const classRealTimeValue = `realTimeCellValue ${(delta == null) ? "" : "realTimeCellValueHighlight"}`;

  return (
    <div className="realTimeCell" ref={elementRef} style={style}>
      <i className={classIcon}></i>&nbsp;
      <span className={classRealTimeDelta}>
        {delta}
      </span>
      <span className={classRealTimeValue}>
        {value}
      </span>
    </div>
  );
});

export default RealTimeRenderer;