interface FooterProps {
}

export const Footer: React.FC<FooterProps> = ({}) => {
    const currentYear = new Date().getFullYear();
    return (
        <footer className="nmPageFooter">
             <strong>Copyright © {currentYear} <a href="nscomponent.com">NSComponent</a>.</strong> All rights reserved.
         </footer>               
    );
};