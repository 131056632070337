import { INSMessageBoxReactSettings, NSAjax, NSAjaxReact, NSMessageBoxReact } from 'nscomponentsreact';
import React, { useEffect, useRef, useState } from 'react';
import { Footer } from "./footer";
import { Header } from "./header";

import { LoadingSpinner } from '../modules/helper/loadingSpinner';
import { PopUpService } from '../modules/helper/popUpService';
import { TextAnimation } from './animation/textAnimation';
import { ErrorBoundary } from './errorBoundary';
import { GlobalContext, GlobalContextType } from './globalContext';
import Authenticate from './routes/authenticate';

import 'bootstrap/dist/css/bootstrap.css';
import 'font-awesome/css/font-awesome.css';
import '../external/css/nmComponent.css';
import '../external/css/nmComponentBlack.css';
import '../external/css/nmComponentWhite.css';
import '../index.css';
import { ThemeProvider } from '../theme/themeProvider';
import './App.css';
import './pleaseWait.css';
import { MasterDetailCustomGridDemo } from '../modules/grid/masterDetailCustomGridDemo';


const App: React.FC = () => {

  const setContextState = (item: any) => {
    setState(prev => ({ ...prev, ...item }));
  };

  const getURL = (methodName:string): string =>  {
    return (state.url + methodName);
  }

  const globalIntercept = (response: any, callback: any) => {
      if(response && response["STATUS"] && response["STATUS"] === "FAILURE") {
          globalCatchBlock(response["MESSAGE"]);
      } else if(callback) {
          callback(response);
      }
  };

  const globalCatchBlock = (error: any) => {
      if(error) {
        state.popUpService && (state.popUpService as PopUpService).showFailureAlertPopup(error);
      }
  };

  const [state, setState] = useState<GlobalContextType>({
      ajax: new NSAjaxReact({dataType: "JSON"}),
      isProd: process.env.NODE_ENV === "production",
      url: process.env.NODE_ENV === "production" ? "api/" : "http://localhost:8080/reactDemo/api/",
      baseName: process.env.NODE_ENV === "production" ? "/NSComponent" : "",
      userObj: null,
      isLoggedIn: false,
      isAuthorized: false,
      setProp: setContextState,
      getURL: getURL,
      globalCatchBlock: globalCatchBlock,
      globalIntercept: globalIntercept,
      modal: null,
      popUpService: null,
      theme: null,
      route: window.location.pathname ? window.location.pathname : null,
      enableAnimation: true,
      animate: false,
      animationEnd: false,
      initialized: false,
  });

  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [toggleNav, setToogleNav] = useState<boolean>(false);
  const [initializeAuth, setInitializeAuth] = useState<boolean>(false);

  const modalRef = useRef<NSMessageBoxReact>(null);
  const appContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
      const resizeHandler = () => {
          if(appContainerRef.current) {
              appContainerRef.current.style.height = `${window.innerHeight}px`;
          }
      };
      window.addEventListener('resize', resizeHandler);
      setTimeout(() => {
        setContextState({ animate: true });
      }, 500);
      fetchData();
      return () => window.removeEventListener('resize', resizeHandler);
  }, []);

  const fetchData = async () => {
    try {
        const response = await fetch(`json/country.json`);
        if (!response.ok) {
          throw new Error('Failed to fetch JSON file');
        }
      
        const jsonData = await response.json();
        //console.log("*************", jsonData)
        return jsonData;
    } catch (err) {
        console.error(err);
    }
  };

  useEffect(() => {
      if(state.animationEnd && !state.initialized) {
          initialize();
      }
  }, [state.animationEnd]);

  const initialize = () => {
      getUserInfo();
      let popUpService: PopUpService;
      if(modalRef.current) {
        popUpService = new PopUpService(modalRef.current);
      }
      window.addEventListener(NSAjax.REQUEST_STARTED,function(){
        setShowLoader(true);
      });
      window.addEventListener(NSAjax.ALL_REQUEST_COMPLETED,function(){
        setShowLoader(false);
      });
      setState(prev => ({ ...prev, initialized: true, modal: modalRef.current, popUpService: popUpService }));
  };

  const endAnimation = () => {
      setTimeout(() => {
          setState(prev => ({ ...prev, animate: false, animationEnd: true }));
      }, 900);
  };

  const getUserInfo = () => {
      // Dummy function to simulate user info retrieval
      setTimeout(() => {
          if(!state.userObj) {
              setShowLoader(false);
              let response = {employeeName: "NSComponent", environment: "PROD", userRole: "Employee"};
              let item = {userObj: response, isLoggedIn: true, isAuthorized: true};
              setContextState(item);
              setInitializeAuth(true);
          }
      }, 500);
  };

  const toggleNavigation = () => {
      setToogleNav(!toggleNav);
  };

  const containerStyle = {minHeight: "100vh", height: '100vh'};//{height: window.innerHeight - 20};
  const modalSetting: INSMessageBoxReactSettings = {enableModalCloseOnEscape: true, enableCloseIconVisibility: true};
  const bgStyles: Record<string, string> = {
      background: 'linear-gradient(135deg, #723362, #9d223c)',
      padding: '36px',
      paddingTop: '48px',
      minHeight: "100vh"
  };
  const textStyles: Record<string, string> = {
      color: 'white',
      fontSize: '24px',
      lineHeight: '36px',
      fontFamily: 'sans-serif',
      textAlign: 'center',
      letterSpacing: '1em',
      paddingLeft: '1em',
      paddingTop: "calc(25vh - 1px)",
  };

  return (
      <GlobalContext.Provider value={state}>
          <ErrorBoundary>
              <ThemeProvider>
                <div id="divAppContainer" ref={appContainerRef} style={containerStyle}>
                    {(state.enableAnimation && !state.animationEnd) && (
                        <div style={bgStyles}>
                            <div style={textStyles}>
                                <TextAnimation show={state.animate} timingFunction="ease-in-out" charClassName="textAnimation" breakChar="|" animationEndCallback={endAnimation}>
                                    WELCOME TO|WORLD OF|NSCOMPONENT
                                </TextAnimation>
                            </div>
                        </div>
                    )}
                    {(!state.enableAnimation || state.animationEnd) && (
                        <div id="divPageContainer" className="nmPageContainer">
                            <Header headerDetails={state.userObj} toggleNavigation={toggleNavigation}  />
                            <Authenticate initialize={initializeAuth} toggleNavigation={toggleNav} />
                            <Footer />
                        </div>
                    )}
                    <LoadingSpinner show={showLoader} />
                    <NSMessageBoxReact setting={modalSetting} ref={modalRef} />
                </div>
              </ThemeProvider>
          </ErrorBoundary>
      </GlobalContext.Provider>
  );
};

export default App;
