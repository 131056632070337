import React, { useState, useEffect, useRef } from 'react';
import './textAnimation.css';

interface TextAnimationProps {
  text?: string;
  show?: boolean;
  transitionTime?: number;
  timingFunction?: string;
  delayMin?: number;
  delayMax?: number;
  threshold?: number;
  style?: React.CSSProperties;
  className?: string;
  charClassName?: string;
  children?: React.ReactNode;
  animationEndCallback?: () => void;
  breakChar?: string;
}

export const TextAnimation: React.FC<TextAnimationProps> = ({
  text = '',
  show = false,
  transitionTime = 1300,
  timingFunction = 'linear',
  delayMin = 200,
  delayMax = 1200,
  threshold = 0.2,
  style = {},
  className = '',
  charClassName = '',
  children = undefined,
  animationEndCallback,
  breakChar = undefined,
}) => {
  const arrSpan = useRef<(HTMLSpanElement | null)[]>([]);
  const [totalAnimationCompleted, setTotalAnimationCompleted] = useState(0);
  const [totalCharLength, setTotalCharLength] = useState(0);

  useEffect(() => {
    // Setup or change dependent on text or children
    const displayedText = text || children?.toString() || '';
    const textArray = displayedText.split('');
    setTotalCharLength(textArray.filter(ch => ch !== breakChar).length);
  }, [text, children]);

  useEffect(() => {
    if (breakChar && breakChar.length > 1) {
      console.warn("breakChar property should be a character. Since it is more than 1 character it will not be used.");
    }
  }, [breakChar]);

  const getDelays = (length: number) => {
    const randoms = getRandoms(length, threshold);
    return randoms.map(num => randomToDelay(num, delayMin, delayMax));
  };

  const transitionEnd = (index: number) => {
    setTotalAnimationCompleted(count => {
      const updatedCount = count + 1;
      if (updatedCount === totalCharLength && animationEndCallback) {
        animationEndCallback();
      }
      return updatedCount;
    });
  };

  const renderToSpan = ({ character, delay }: { character: string, delay: number }, index: number) => {
    const styleSpan = {
      opacity: show ? '1' : '0',
      transition: `opacity ${transitionTime}ms`,
      transitionDelay: `${delay}ms`,
      transitionTimingFunction: timingFunction,
    };

    if(character == breakChar) {
          return <br key={index}/>
    }
    else {
      return (
        <span
          className={charClassName}
          ref={el => addSpanRef(el, index)}
          key={index}
          style={styleSpan}
          onTransitionEnd={() => transitionEnd(index)}
        >
          {character}
        </span>
      );
    }
  };

  const addSpanRef = (node: HTMLSpanElement | null, index: number) => {
    arrSpan.current[index] = node;
  };

  const displayedText = text || children?.toString() || '';
  const textArray = displayedText.split('');
  const delays = getDelays(textArray.length);

  return (
    <div style={style} className={className}>
      {textArray.map((char, index) => renderToSpan({ character: char, delay: delays[index] }, index))}
    </div>
  );
};

function getRandoms(length: number, threshold: number): number[] {
  const tooClose = (a: number, b: number) => Math.abs(a - b) < threshold;
  const result: number[] = [];
  let random: number;

  for (let i = 0; i < length; i++) {
    random = Math.random();
    if (i !== 0) {
      const prev = result[i - 1];
      while (tooClose(random, prev)) {
        random = Math.random();
      }
    }
    result.push(random);
  }
  return result;
}

function randomToDelay(random: number, min: number, max: number): number {
  const float = random * (max - min);
  return Math.floor(float) + min;
}

function getTransitionEndEventName(): string {
  const transitions: { [key: string]: string } = {
    "transition": "transitionend",
    "OTransition": "oTransitionEnd",
    "MozTransition": "transitionend",
    "WebkitTransition": "webkitTransitionEnd"
  };
  const bodyStyle = document.body.style as any;
  for (const transition in transitions) {
    if (bodyStyle[transition] !== undefined) {
      return transitions[transition];
    }
  }
  return "transitionend";
};