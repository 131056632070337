import React, { useRef, useState } from 'react';
import { NSDatePickerReact, INSDatePickerReactSetting, INSDatePickerCustomClass } from 'nscomponentsreact';
import { useTheme } from '../../theme/themeContext';

interface DatePickerProps {
  // Define any props you expect to receive, if applicable
}

export const DatePicker: React.FC<DatePickerProps> = (props) => {
  const { theme, oldTheme } = useTheme();
  const [selectedDate, setSelectedDate] = useState<string | null>(null);

  const calSimple = useRef<NSDatePickerReact>(null);
  const calMinMax = useRef<NSDatePickerReact>(null);
  const calDisabled = useRef<NSDatePickerReact>(null);
  const calDisabledTextBox = useRef<NSDatePickerReact>(null);
  const calCustomButton = useRef<NSDatePickerReact>(null);
  const calFooter = useRef<NSDatePickerReact>(null);
  const calFooterTimeZone = useRef<NSDatePickerReact>(null);

  const arrTimezone = [
                   { name: "(GMT -12:00) Eniwetok, Kwajalein", value: "-12:00"},
                   { name: "(GMT -11:00) Midway Island, Samoa", value: "-11:00"},
                   { name: "(GMT -10:00) Hawaii", value: "-10:00"},
                   { name: "(GMT -9:30) Taiohae", value: "-09:50"},
                   { name: "(GMT -9:00) Alaska", value: "-09:00"},
                   { name: "(GMT -8:00) Pacific Time (US &amp; Canada)", value: "-08:00"},
                   { name: "(GMT -7:00) Mountain Time (US &amp; Canada)", value: "-07:00"},
                   { name: "(GMT -6:00) Central Time (US &amp; Canada), Mexico City", value: "-06:00"},
                   { name: "(GMT -5:00) Eastern Time (US &amp; Canada), Bogota, Lima", value: "-05:00"},
                   { name: "(GMT -4:30) Caracas", value: "-04:50"},
                   { name: "(GMT -4:00) Atlantic Time (Canada), Caracas, La Paz", value: "-04:00"},
                   { name: "(GMT -3:30) Newfoundland", value: "-03:50"},
                   { name: "(GMT -3:00) Brazil, Buenos Aires, Georgetown", value: "-03:00"},
                   { name: "(GMT -2:00) Mid-Atlantic", value: "-02:00"},
                   { name: "(GMT -1:00) Azores, Cape Verde Islands", value: "-01:00", selected:true},
                   { name: "(GMT) Western Europe Time, London, Lisbon, Casablanca", value: "+00:00"},
                   { name: "(GMT +1:00) Brussels, Copenhagen, Madrid, Paris", value: "+01:00"},
                   { name: "(GMT +2:00) Kaliningrad, South Africa", value: "+02:00"},
                   { name: "(GMT +3:00) Baghdad, Riyadh, Moscow, St. Petersburg", value: "+03:00"},
                   { name: "(GMT +3:30) Tehran", value: "+03:50"},
                   { name: "(GMT +4:00) Abu Dhabi, Muscat, Baku, Tbilisi", value: "+04:00"},
                   { name: "(GMT +4:30) Kabul", value: "+04:50"},
                   { name: "(GMT +5:00) Ekaterinburg, Islamabad, Karachi, Tashkent", value: "+05:00"},
                   { name: "(GMT +5:30) Bombay, Calcutta, Madras, New Delhi", value: "+05:50"},
                   { name: "(GMT +5:45) Kathmandu, Pokhara", value: "+05:75"},
                   { name: "(GMT +6:00) Almaty, Dhaka, Colombo", value: "+06:00"},
                   { name: "(GMT +6:30) Yangon, Mandalay", value: "+06:50"},
                   { name: "(GMT +7:00) Bangkok, Hanoi, Jakarta", value: "+07:00"},
                   { name: "(GMT +8:00) Beijing, Perth, Singapore, Hong Kong", value: "+08:00"},
                   { name: "(GMT +8:45) Eucla", value: "+08:75"},
                   { name: "(GMT +9:00) Tokyo, Seoul, Osaka, Sapporo, Yakutsk", value: "+09:00"},
                   { name: "(GMT +9:30) Adelaide, Darwin", value: "+09:50"},
                   { name: "(GMT +10:00) Eastern Australia, Guam, Vladivostok", value: "+10:00"},
                   { name: "(GMT +10:30) Lord Howe Island", value: "+10:50"},
                   { name: "(GMT +11:00) Magadan, Solomon Islands, New Caledonia", value: "+11:00"},
                   { name: "(GMT +11:30) Norfolk Island", value: "+11:50"},
                   { name: "(GMT +12:00) Auckland, Wellington, Fiji, Kamchatka", value: "+12:00"},
                   { name: "(GMT +12:45) Chatham Islands", value: "+12:75"},
                   { name: "(GMT +13:00) Apia, Nukualofa", value: "+13:00"},
                   { name: "(GMT +14:00) Line Islands, Tokelau", value: "+14:00"}
  ];

  const themeChanged = () => {
    if(theme !== oldTheme) {
        if(calSimple.current) {
            calSimple.current.setTheme(theme);
        }
        if(calMinMax.current) {
            calMinMax.current.setTheme(theme);
        }
        if(calDisabled.current) {
            calDisabled.current.setTheme(theme);
        }
        if(calDisabledTextBox.current) {
          calDisabledTextBox.current.setTheme(theme);
        }
        if(calCustomButton.current) {
          calCustomButton.current.setTheme(theme);
        }
        if(calFooter.current) {
          calFooter.current.setTheme(theme);
        }
        if(calFooterTimeZone.current) {
          calFooterTimeZone.current.setTheme(theme);
        }
    }
  };

  const dateSelected = (type: string, destID: string, event: any) => {
    const div = document.querySelector("#" + destID);
    if (div) {
      div.innerHTML = event.detail;
    }
  };

  const disabledDay = (date: Date) => {
    const todayDate = new Date();
    return date < todayDate;
  };

  const getFooter = () => {
    const divFooter = document.createElement("div");
    divFooter.setAttribute("class", "calFooter");
    const span = document.createElement("span");
    span.setAttribute("class", "btn-group pull-left");
    const btnToday = document.createElement("button");
    btnToday.setAttribute("class", "btn btn-sm btn-info");
    btnToday.innerHTML = "Today";
    btnToday.onclick = () => calFooter.current?.setTodayDate();
    span.appendChild(btnToday);

    const btnClear = document.createElement("button");
    btnClear.setAttribute("class", "btn btn-sm btn-danger");
    btnClear.innerHTML = "Clear";
    btnClear.onclick = () => calFooter.current?.reset();
    span.appendChild(btnClear);

    const btnClose = document.createElement("button");
    btnClose.setAttribute("class", "btn btn-sm btn-success pull-right");
    btnClose.innerHTML = "Close";
    btnClose.onclick = () => calFooter.current?.closeCalendar();
    span.appendChild(btnClose);

    divFooter.appendChild(span);
    return divFooter;
  };

  const getTimezone = () => {
    const divFooter = document.createElement("div");
    divFooter.setAttribute("class", "calFooter");
    const span = document.createElement("span");
    span.setAttribute("class", "btn-group pull-left");
    const btnToday = document.createElement("button");
    btnToday.setAttribute("class", "btn btn-sm btn-info");
    btnToday.innerHTML = "Today";
    btnToday.onclick = () => calFooterTimeZone.current?.setTodayDate();
    span.appendChild(btnToday);

    const btnClear = document.createElement("button");
    btnClear.setAttribute("class", "btn btn-sm btn-danger");
    btnClear.innerHTML = "Clear";
    btnClear.onclick = () => calFooterTimeZone.current?.reset();
    span.appendChild(btnClear);

    const select = document.createElement("select");
    select.style.width = "250px";
    arrTimezone.forEach(tz => {
      const option = document.createElement("option");
      option.text = tz.name;
      option.value = tz.value;
      select.appendChild(option);
    });
    span.appendChild(select);

    const btnClose = document.createElement("button");
    btnClose.setAttribute("class", "btn btn-sm btn-success pull-right");
    btnClose.innerHTML = "Close";
    btnClose.onclick = () => calFooterTimeZone.current?.closeCalendar();
    span.appendChild(btnClose);

    divFooter.appendChild(span);
    return divFooter;
  };

  const selectValue = () => {
    setSelectedDate('05/10/2024');
  };

  themeChanged();

  let conStyle: React.CSSProperties = { display: "flex", flexDirection: 'row', flexWrap: 'wrap', paddingLeft: '50px', paddingTop: "50px" };
  let cellStyle: React.CSSProperties = { display: "flex", flexDirection: 'column', width: "33%" };
  let customClass = { textInput: "datePickerTextBox" };
  let setting = { customClass: customClass };
  let minMaxsetting = { inputDateFormat: "MM/dd/yyyy", minDate: "01/01/2021", maxDate: new Date(), selectedDate: new Date(), customClass: customClass };
  let disabledSetting = { markDayDisabled: disabledDay, customClass: customClass };
  let disabledTextBoxSetting = { enableTextBoxDisabled: true, customClass: customClass };
  let customButtonSetting = { buttonHtml: "<i class='fa fa-calendar'></i>", placeHolder: "yyyy-mm-dd", dateOutputFormat: "yyyy-MM-dd", customClass: customClass };
  let settingFooter = { showFooter: true, footerContent: getFooter(), customClass: customClass };
  let timezoneSetting = { showFooter: true, footerContent: getTimezone(), calendarWidth: 300, customClass: customClass };

  return (
    <div className="box box-ns-red">
      <div className="box-header">
        <h3 className="box-title">DatePicker Demo</h3>
      </div>
      <div className="box-body">
        <div className="row" style={conStyle}>
          <div style={cellStyle}>
            <p>Basic Date Picker</p>
            <div style={{display: "flex", flexDirection: "column", gap: "20px"}}>
                <div style={{display: "flex", flexDirection: "row", gap: "20px"}}>
                    <NSDatePickerReact setting={setting} ref={calSimple} value={selectedDate} onDateSelected={(e: any) => dateSelected("simple", "divSimpleCalSelectedDate", e)} />
                    <button type="button" onClick={selectValue}>Select Today's Date</button>
                </div>
                <div id="divSimpleCalSelectedDate" style={{ height: "60px" }}>&nbsp;</div>
            </div>
          </div>
          <div style={cellStyle}>
            <p>Date Picker with Min Max date(minDate = "01/01/2021" and maxDate = Today's date)</p>
            <NSDatePickerReact setting={minMaxsetting} ref={calMinMax} onDateSelected={(e: any) => dateSelected("minmax", "divCalMinMaxSelectedDate", e)} />
            <div id="divCalMinMaxSelectedDate" style={{ height: "60px" }}>&nbsp;</div>
          </div>
          <div style={cellStyle}>
            <p>Calendar with Disabled Date (date less than today is disabled)</p>
            <NSDatePickerReact setting={disabledSetting} ref={calDisabled} onDateSelected={(e: any) => dateSelected("disabled", "divCalDisabledSelectedDate", e)} />
            <div id="divCalDisabledSelectedDate" style={{ height: "60px" }}>&nbsp;</div>
          </div>
          <div style={cellStyle}>
            <p>Date Picker with Disabled Textbox</p>
            <NSDatePickerReact setting={disabledTextBoxSetting} ref={calDisabledTextBox} onDateSelected={(e: any) => dateSelected("disabledtextbox", "divDisabledTextBoxSelectedDate", e)} />
            <div id="divDisabledTextBoxSelectedDate" style={{ height: "60px" }}>&nbsp;</div>
          </div>
          <div style={cellStyle}>
            <p>Date Picker with Custom Button</p>
            <NSDatePickerReact setting={customButtonSetting} ref={calCustomButton} onDateSelected={(e: any) => dateSelected("custombutton", "divCustomButtonSelectedDate", e)} />
            <div id="divCustomButtonSelectedDate" style={{ height: "60px" }}>&nbsp;</div>
          </div>
          <div style={cellStyle}>
            <p>Calendar With Footer</p>
            <NSDatePickerReact setting={settingFooter} ref={calFooter} onDateSelected={(e: any) => dateSelected("footer", "divFooterCalSelectedDate", e)} />
            <div id="divFooterCalSelectedDate" style={{ height: "60px" }}>&nbsp;</div>
          </div>
          <div style={cellStyle}>
            <p>Date Picker With Footer and Timezone</p>
            <NSDatePickerReact setting={timezoneSetting} ref={calFooterTimeZone} onDateSelected={(e: any) => dateSelected("footertimezone", "divFooterCalTimezoneSelectedDate", e)} />
            <div id="divFooterCalTimezoneSelectedDate" style={{ height: "60px" }}>&nbsp;</div>
          </div>
        </div>
      </div>
    </div>
  );
};