import React, {forwardRef, useEffect, useImperativeHandle, useRef, memo, useState} from 'react';

export const TextBoxEditor = memo(forwardRef((props, ref) => {
    const [value, setValue] = useState("");
    const refInput = useRef<HTMLInputElement | null>(null);
    const refValue = useRef<string>('');
    const dataRef = useRef<any>({});

    useEffect(() => {
        refValue.current = value;
    });

    useEffect(() => {
        refInput.current?.focus();
    }, []);

    useImperativeHandle(ref, () => {
        return {
            init(data:any) {
                console.log("in init with data ", data)
                setValue(data.defaultValue);
                dataRef.current = data;
            },

            elementAdded() {
                console.log("elementAdded")
            },
            getValue() {
                return refValue.current;
            }
        };
    });

    //const key: string = dataRef.current ? `${dataRef.current.rowIndex}-${dataRef.current.cellIndex}` : '';
    return (
        <input id="txt" type="text"
               ref={refInput}
               value={value}
               onChange={event => setValue(event.target.value)}
               style={{width: "100%"}}
        />
    );
}));