import React, { useState, useRef, useEffect, useImperativeHandle, forwardRef, useCallback } from 'react';
import { INSDashboardBodyComponent } from 'nscomponentsreact';
import { objImg } from './images';

interface AnimalComponentProps {
  data?: any;
}

export interface AnimalComponentRef {
  init: (data: any) => void;
  getElement: () => HTMLElement | null;
  elementAdded: () => void;
  fullScreenChanged?: (isFullScreen: boolean) => void;
}

export const AnimalComponent = React.memo(forwardRef<AnimalComponentRef, AnimalComponentProps>((props, ref) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const elementRef = useRef<HTMLDivElement | null>(null);
  const [url, setUrl] = useState<string | null>(null);
  const [value, setValue] = useState<string | null>(null);
  const [width, setWidth] = useState<number>(500);

  const getWidth = useCallback((): number => {
    let newWidth = 500;
    if (elementRef.current) {
      const rect = elementRef.current.getBoundingClientRect();
      if (rect.width > newWidth) {
        newWidth = rect.width - 50;
      }
    }
    return newWidth;
  }, []);

  const init = useCallback((data: any) => {
    setUrl(objImg[data.name].url);
    setValue(data.name);
    setWidth(getWidth());
  }, [objImg, getWidth]);

  const getElement = useCallback(() => {
    return containerRef.current;
  }, []);

  const elementAdded = useCallback(() => {
    // Implementation for element added if needed
  }, []);

  const fullScreenChanged = useCallback((isFullScreen: boolean) => {
    setWidth(getWidth());
  }, [getWidth]);

  useImperativeHandle(ref, () => ({
    init,
    getElement,
    elementAdded,
    fullScreenChanged,
  }));

  useEffect(() => {
    if (url) {
      const updatedUrl = url.replace('###width###', width.toString());
      setUrl(updatedUrl);
    }
  }, [width, url]);

  useEffect(() => {
    const handleResize = () => {
      setWidth(getWidth());
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [getWidth]);

  const style = { width: '100%', height: '100%' };

  return (
    <div id="divImageCon" ref={elementRef} style={style}>
      <img crossOrigin="anonymous" src={url || ''} alt={value || ''} />
    </div>
  );
}));