import React from 'react';

export interface GlobalContextType {
    ajax: any;
    isProd: boolean;
    url: string;
    baseName: string;
    userObj: any;
    isLoggedIn: boolean;
    isAuthorized: boolean;
    setProp: any;
    getURL: any;
    globalCatchBlock: any;
    globalIntercept: any;
    modal: any;
    popUpService: any;
    theme: any;
    route: any;
    enableAnimation: boolean;
    animate: boolean;
    animationEnd: boolean;
    initialized: boolean;
  }

let defaultState: GlobalContextType = {
                ajax: null,
                isProd: false,
                url: "",
                baseName: "",
                userObj: null,
                isLoggedIn: false,
                isAuthorized: false,
                setProp: null,
                getURL: null,
                globalCatchBlock: null,
                globalIntercept: null,
                modal: null,
                popUpService: null,
                theme: null,
                route: null,
                enableAnimation: true,
                animate: false,
                animationEnd: false,
                initialized: false,
};

export const GlobalContext = React.createContext<GlobalContextType>(defaultState);

export function useGlobalContext()
{
    return React.useContext(GlobalContext);
};
