import React, { useState, useEffect, useRef, useImperativeHandle, forwardRef } from 'react';
import { INSGridRendererComponent } from 'nscomponentsreact';

interface ProficiencyRendererProps {
  data?: any;
  dataField?: string;
  index?: number;
  colIndex?: number;
  row?: any;
}

export interface ProficiencyRendererHandle {
  setData: (data: any, dataField: string, index: number, colIndex: number, row: any) => void;
  getElement: () => HTMLElement | null;
}

const ProficiencyRenderer = forwardRef<ProficiencyRendererHandle, ProficiencyRendererProps>((props, ref) => {
  const [item, setItem] = useState<any>(null);
  const [color, setColor] = useState<string>("");
  const [value, setValue] = useState<number>(0);
  const elementRef = useRef<HTMLDivElement | null>(null);

  // Imperative Handle for external methods
  useImperativeHandle(ref, () => ({
    setData: (data: any, dataField: string, index: number, colIndex: number, row: any) => {
      const proficiencyValue: number = data["proficiency"];
      let color = "";
      if (proficiencyValue < 20) {
        color = "#f55d51";
      } else if (proficiencyValue < 60) {
        color = "#ffb300";
      } else {
        color = "#82d249";
      }
      setItem(data);
      setColor(color);
      setValue(proficiencyValue);
    },
    getElement: () => elementRef.current,
  }));

  const style = { width: "100%" };
  const barStyle = { width: value + "%", backgroundColor: color };

  return (
    <div className="proficiency" ref={elementRef} style={style}>
      <div className="proficiencyWrapper">
        <div className="proficiencyBar" style={barStyle}></div>
      </div>
      <div className="proficiencyValue">{value}%</div>
    </div>
  );
});

export default ProficiencyRenderer;
