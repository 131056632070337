import React, { useState, useEffect } from 'react';
import { NSDatePickerReact, INSDatePickerReactSetting, INSDatePickerCustomClass } from 'nscomponentsreact';

interface PanelProps {
    // Define any props you expect to receive, if applicable
}

export const Panel: React.FC<PanelProps> = (props) => {

    return (
        <div className="box box-ns-red">
            <div className="box-header">
                <h3 className="box-title">Panel Demo</h3>
            </div>
            <div className="box-body">
            
            </div>
        </div>
    );
};
