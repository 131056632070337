
import React, {forwardRef, useEffect, useImperativeHandle, useRef, memo, useState} from 'react';

export const CountryRenderer = memo(forwardRef((props, ref) => {
    const [value, setValue] = useState("");
    const [url, setUrl] = useState("");
    const [item, setItem] = useState({});
    const refElement = useRef(null);

    useEffect(() => {
        
    }, []);

    /* Component Editor Lifecycle methods */
    useImperativeHandle(ref, () => {
        return {
            setData(data: any,dataField: string,index: number,colIndex: number,row: any): void
            { 
                const url: string = "https://flagcdn.com/h20/" + data["countryIcon"] + ".png";
                setItem(data);
                setUrl(url);
                setValue(data[dataField]);
                //console.log(this.context);
            },
            getElement(): any
            {
                return refElement.current;
            },
            elementAdded() {
                console.log("elementAdded")
            },
            getValue() {
                return value;
            }
        };
    });

    const style = {width: "100%"};
    return (
        <span ref={refElement} style={style}>
                   <img width='15' height='10' style={{marginBottom: "2px"}} src={url} alt={value} />
                   &nbsp;&nbsp;{value}
        </span>
    );
}));