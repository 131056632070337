import React from "react";
import { Route, Routes } from "react-router-dom";
import { AutoComplete } from "../../modules/autocomplete/autocomplete";
import { Calendar } from "../../modules/calendar/calendar";
import { Dashboard } from "../../modules/dashboard/dashboard";
import { DatePicker } from "../../modules/datePicker/datePicker";
import { ExpressionEvaluator } from "../../modules/expressionEvaluator/expressionEvaluator";
import FlatGridDemo from "../../modules/grid/FlatGridDemo";
import GroupingGridDemo from "../../modules/grid/groupingGridDemo";
import HierarchicalGridDemo from "../../modules/grid/hierarchicalGridDemo";
import { Home } from "../../modules/home/home";
import MultiSelectDropdown from "../../modules/multiSelectDropdown/multiSelectDropdown";
import { PageNotFound } from "../../modules/pageNotFound/pageNotFound";
import { Panel } from "../../modules/panel/panel";
import { isUndefinedOrNull } from "../../util/commonUtil";
import { useAppNavigation } from "./useAppNavigation";
import RealTimeUpdates from "../../modules/grid/realTimeGrid";
import { MasterDetailCustomGridDemo } from "../../modules/grid/masterDetailCustomGridDemo";

export interface IProps {
    menuList: any[];
    selectedMenu: string;
}

export const AppRoutes: React.FC<IProps> = ({menuList, selectedMenu}) => {
    const [menus, setMenus] = React.useState<any>(null);
    //const [menuList, setMenuList] = React.useState<any[]>([]);
    const navigate = useAppNavigation();
    //for dynamic navigation use like below
    // navigate('/registrationQueue');

    /*React.useEffect(() => {
        
        setMenuList([...source]);
    }, []);*/

    React.useEffect(() => {
        if(selectedMenu) {
            navigate(selectedMenu);
        }
    }, [selectedMenu]);

    React.useEffect(() => {
        if (menuList?.length > 0) {
            let accumulator: any[] = [];
            menuList?.forEach((menu: any, index: number) => {
                accumulator = addMenuInArray(accumulator, menu, index);
                if (menu.childMenus?.length > 0) {
                    menu.childMenus.forEach((childMenu: any, childIndex: number) => {
                        accumulator = addMenuInArray(accumulator, childMenu, childIndex);
                        setOtherAccessiblePath(accumulator,childMenu.otherAccessiblePath);
                    });
                }
                setOtherAccessiblePath(accumulator,menu.otherAccessiblePath);
            });
            accumulator.push(<Route key="/" path="/" element={menuRouteToBeInclude['home']}/>);
         

            console.log(accumulator); 

            setMenus(accumulator);
           
        }

    }, [menuList]);


    const setOtherAccessiblePath = (accumulator: any[], menu: string[]) => {
     
      if (menu && menu.length > 0) {
        console.log(menu) ;   
        menu.forEach((otherPath) => {
          if (!accumulator.includes(otherPath))
            accumulator.push(
              <Route
                path={otherPath}
                element={menuRouteToBeInclude[otherPath]}
              />
            );
        });
      }
    };

    const addMenuInArray = (accumulator: any[], menu: any, index: number, parentIndex?: number) => {
        if (menu?.link?.startsWith('/') && !menu?.isOpenInNewWindow) {
            const routeKey = isUndefinedOrNull(parentIndex) ? index : (index + '-' + parentIndex);
            const key = menu.link.substring(1);
            const menuName = '/' + key;
            accumulator.push(<Route key={menuName} path={menuName} element={menuRouteToBeInclude[key]}></Route>);
        }
        return accumulator;
    };

    const menuRouteToBeInclude: Record<string, any> = {
        home: <Home />,
        hierarchicalGrid: <HierarchicalGridDemo />,
        groupingGrid: <GroupingGridDemo/>,
        flatGrid: <FlatGridDemo/>  ,
        realTimeGrid:  <RealTimeUpdates/>,
        masterDetail:  <MasterDetailCustomGridDemo/>,
        calendar:  <Calendar />,
        datePicker:  <DatePicker />,
        panel:  <Panel/>,
        dashboard:  <Dashboard/>,
        autocomplete:  <AutoComplete/>,
        expressionEvaluator: <ExpressionEvaluator />,
        multiSelectDropdown: <MultiSelectDropdown />,
        default: <PageNotFound />
    };
    
    return (
        <>
            {(menus && menus.length > 0) && (
                    <Routes>
                        {menus}
                    </Routes>
            )}
        </>
    );
}