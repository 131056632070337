import React, { useState, useRef, useEffect, forwardRef, useImperativeHandle } from "react";
import { INSMessageBoxBodyComponent } from "nscomponentsreact";

interface PetComponentProps {
    data: {
        url: string;
        name: string;
    };
}

const PetComponent = forwardRef<INSMessageBoxBodyComponent, PetComponentProps>((props, ref) => {
    const [url, setUrl] = useState<string | null>(null);
    const [value, setValue] = useState<string | null>(null);
    const elementRef = useRef<HTMLDivElement | null>(null);

    // useImperativeHandle exposes the methods you want for the parent to call
    useImperativeHandle(ref, () => ({
        init(data: { url: string; name: string }) {
            setUrl(data.url);
            setValue(data.name);
        },
        getElement() {
            return elementRef.current;
        },
        elementAdded() {
            // Custom behavior if needed when the element is added
        },
    }));

    useEffect(() => {
        // Any setup needed on mount
        if (props.data) {
            setUrl(props.data.url);
            setValue(props.data.name);
        }
    }, [props.data]);

    const style = { width: "100%" };

    return (
        <div id="divImageCon" ref={elementRef} style={style}>
            <img crossOrigin="anonymous" src={url || ""} alt={value || ""}></img>
        </div>
    );
});

export default PetComponent;