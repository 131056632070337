import React from 'react';

interface HomeProps {
    // Define any props here if necessary
}

export const Home: React.FC<HomeProps> = () => {
    const arrComp = [
        { label: "Grid (Tree Grid, Flat Grid)" },
        { label: "Editor" },
        { label: "Calendar" },
        { label: "Date Picker" },
        { label: "Multiselect Dropdown" },
        { label: "Textbox" },
        { label: "Panel" },
        { label: "Dashboard" },
        { label: "Message Box" },
        { label: "Message Bar" },
        { label: "Navigation" },
        { label: "Divider Box" },
        { label: "Tab Navigator" },
        { label: "Image Processor" },
    ];

    const arrUtilities = [
        { label: "Ajax" },
        { label: "Docx Export" },
        { label: "XLSX Export" },
        { label: "Resizable Table" },
        { label: "Router" },
    ];

    const styleOl = { listStyleType: "decimal" };
    const styleSpan = { fontWeight: "bold" };

    const compComp = arrComp.map((item, index) => (
        <li key={index}><span style={styleSpan}>{item.label}</span></li>
    ));

    const compUtilities = arrUtilities.map((item, index) => (
        <li key={index}><span style={styleSpan}>{item.label}</span></li>
    ));

    return (
        <div className="box box-ns-red">
            <div className="box-header">
                <h3 className="box-title">Home</h3>
            </div>
            <div className="box-body">
                <p>
                    <b>NSComponent</b> allows you to build cross-platform, cross-browser web and mobile apps faster.
                    It is a set of ready-to-use HTML5 components written in pure Javascript with no dependencies on other libraries.
                    These Components can be easily combined into a single application interface of any complexity.
                    Each Component can be easily customized via CSS and Configurations.<br />
                    The NSComponent components are compatible with popular JavaScript frameworks such as React, Angular.
                    The Components have React and Angular wrapper so that it can be used in both React and Angular Projects.
                    <br /><br />
                    Some of the Components are:
                </p>
                <ul style={styleOl}>
                    {compComp}
                </ul>
                <br />
                Some of the Utilities developed as part of NSComponent are:
                <ul style={styleOl}>
                    {compUtilities}
                </ul>
                <br />
                Please view the Components in Action by clicking on the demos on <b>Navigation Bar</b>.
            </div>
        </div>
    );
};