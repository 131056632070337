import React, { useState, useEffect, useContext } from 'react';
import { NSReactEvent } from '../modules/helper/nsReactEvent';
import { GlobalContext } from './globalContext';

interface HeaderProps {
    toggleNavigation: () => void;
    headerDetails: any;
}

export const Header: React.FC<HeaderProps> = ({ toggleNavigation, headerDetails }) => {
    const [selectedTheme, setSelectedTheme] = useState("White");
    const context = useContext(GlobalContext);
    const arrTheme = [
        { value: 'White', name: 'White' },
        { value: 'Black', name: 'Black' }
    ];

    useEffect(() => {
        NSReactEvent.getInstance().dispatch("themeChanged", { selectedTheme, oldTheme: null });
    }, []);

    const handleThemeChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
        const newTheme = event.target.value;
        if (newTheme && newTheme !== "-1") {
            NSReactEvent.getInstance().dispatch("themeChanged", { selectedTheme: newTheme, oldTheme: selectedTheme });
            setSelectedTheme(newTheme);
        }
    };

    return (
        <header className="nmPageHeader" style={{ width: "100%" }}>
            <a className="nmLogo">
                <span className="nmLogoTextMini"><b>NS</b></span>
                <span className="nmLogoText"><b>NSComponent</b></span>
            </a>
            <nav className="nmPageHeaderContent" role="navigation">
                <div className="nmHeaderRight">
                    <div className="nmAppName">
                        <h1>Welcome to NSCOMPONENT</h1>
                        {/* Uncomment to use Title component */}
                        {/* <Title title="NSCOMPONENT"></Title> */}
                    </div>
                    <div className="nmAppDetail">
                        <div style={{ display: "inline-block" }}>
                            <select id="cmbTheme" className="select" value={selectedTheme} onChange={handleThemeChange}>
                                <option key="-1" value="-1">Select Theme</option>
                                {arrTheme.map((item, key) => (
                                    <option key={key} value={item.value}>{item.name}</option>
                                ))}
                            </select>
                        </div>
                        <div className="welcomeMessage">
                            Select Theme:&nbsp;&nbsp;&nbsp;
                        </div>
                    </div>
                </div>
            </nav>
        </header>
    );
};