import { INSGridColumn, INSGridCustomClassSetting, INSGridEditorSetting, INSGridReactSettings, NSGridReact } from 'nscomponentsreact';
import React, { useEffect, useRef } from "react";
import { useTheme } from '../../theme/themeContext';
import { DataGenerator } from '../../util/dataGenerator';
import { CountryRenderer } from './components/countryRenderer';
import ProficiencyRenderer from './components/proficiencyRenderer';
import RealTimeRenderer from './components/realTimeRenderer';

const RealTimeUpdates: React.FC = () => {
    const { theme } = useTheme();
    const gridRef = useRef<NSGridReact | null>(null);
    const dataGenerator = new DataGenerator();

    useEffect(() => {
        getData();
    }, []);

    useEffect(() => {
        if (gridRef.current && theme) {
            gridRef.current.setTheme(theme);
        }
    }, [theme]);

    const getData = () => {
        if (gridRef.current) {
            gridRef.current.dataSource(dataGenerator.getRealTimeUpdates(20));
            refreshGrid();
        }
    };

    const refreshGrid = () => {
        setInterval(() => {
            if (gridRef.current) {
                let arrItem: any[] = gridRef.current.getFilteredData();
                for (let item of arrItem) {
                    item.prevAsset = item.asset;
                    item.asset = dataGenerator.changeAsset();
                    item.prevProficiency = item.proficiency;
                    item.proficiency = dataGenerator.changeProficiency();
                    let index: number = gridRef.current.getItemInfo(item).rowIndex;
                    gridRef.current.updateCellByIndex(index, "asset");
                    gridRef.current.updateCellByIndex(index, "proficiency");
                }
            }
        }, 2000);
    };

    const customClass: INSGridCustomClassSetting = {
        headerCell: "columnClass",
        nonFirstBodyColumn: "columnClass"
    };

    const gridColumn: INSGridColumn[] = [
        { headerText: "Name", dataField: "name", width: "200px", filter: { advancedFilterPopUpPos: "bottom-right" } },
        { headerText: "Country", dataField: "country", width: "150px", truncateToFit: true, itemRendererComponent: CountryRenderer },
        { headerText: "Language", dataField: "language", width: "150px", truncateToFit: true },
        { headerText: "Asset", dataField: "asset", width: "180px", itemRendererComponent: RealTimeRenderer },
        { headerText: "Proficiency", dataField: "proficiency", width: "150px", itemRendererComponent: ProficiencyRenderer },
        { headerText: "Phone", dataField: "phoneNumber", width: "180px" }
    ];

    const editorSetting: INSGridEditorSetting = {
        clickType: NSGridReact.EDITOR_EDITING_NOCLICK,
        enableMultipleEdit: true,
    };

    const gridSetting: INSGridReactSettings = {
        type: NSGridReact.GRID_TYPE_NORMAL,
        columnResizable: true,
        columnSortable: true,
        columnDraggable: false,
        enableFilter: true,
        enableAdvancedFilter: true,
        columns: gridColumn,
        customClass: customClass,
        rowKeyField: "id",
        heightOffset: 170,
        enableVirtualScroll: true,
        theme: theme || null
    };

    return (
        <div className="box box-ns-red">
            <div className="box-header">
                <h3 className="box-title">Real Time Grid Demo</h3>
            </div>
            <div className="box-body">
                <NSGridReact
                    setting={gridSetting}
                    dataSource={[]}
                    ref={gridRef}
                    style={{ width: "100%" }}
                />
            </div>
        </div>
    );
};

export default RealTimeUpdates;