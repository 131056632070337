import React, { useRef, useEffect, useState } from 'react';

interface LoadingSpinnerProps {
    show: boolean;
}

export const LoadingSpinner: React.FC<LoadingSpinnerProps> = ({show}) => {
  const elementRef = useRef<HTMLDivElement>(null);

  return (
    <>
        {show && (
            <div className="spinner-container" ref={elementRef}>
            <div className="spinner">
                <i className="fa fa-spinner fa-spin"></i>&nbsp;&nbsp;&nbsp;<b className="modal-title">Please Wait !</b>
            </div>
            </div>
        )}
    </>
  );
};