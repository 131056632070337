import React, { useState, useEffect, ReactNode } from 'react';
import { ThemeContext } from './themeContext';
import { NSReactEvent } from '../modules/helper/nsReactEvent';

interface ThemeProviderProps {
  children: ReactNode;
}

const ThemeProvider: React.FC<ThemeProviderProps> = ({ children }) => {
  const [theme, setTheme] = useState<string>('White');
  const [previousTheme, setPreviousTheme] = useState<string>('');

  useEffect(() => {
    const handleThemeChange = (event: any) => {
        const selectedTheme: string = event.details.selectedTheme;
        const oldTheme: string = event.details.oldTheme;
        setPreviousTheme(oldTheme);
        setTheme(selectedTheme);
    };

    const instance = NSReactEvent.getInstance();
    instance.addListener('themeChanged', handleThemeChange);

    return () => {
      instance.removeListener('themeChanged', handleThemeChange);
    };
  }, []);

  return (
    <ThemeContext.Provider value={{ theme, oldTheme: previousTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};

export { ThemeProvider };