import React, { useState, useEffect, useRef } from "react";
import { NSGridReact, INSGridReactSettings } from 'nscomponentsreact';
import { INSGridColumn, INSGridCustomClassSetting, INSGridEditorSetting } from 'nscomponentsreact';
import { useTheme } from "../../theme/themeContext";
import { useGlobalContext } from "../../app/globalContext";
import { CommonUtil } from "../../util/commonUtil";

const GroupingGridDemo: React.FC = () => {
    const context = useGlobalContext();
    const { theme } = useTheme();

    const [groupBy, setGroupBy] = useState<string>("currency");
    const gridRef = useRef<NSGridReact | null>(null);
    const commonUtil = new CommonUtil();

    const arrGroupBy = [
        { value: 'currency', name: 'Currency' },
        { value: 'name', name: 'Country' },
        { value: 'phone', name: 'Phone Number' }
    ];

    useEffect(() => {
        getData();
    }, []);

    useEffect(() => {
        if (gridRef.current && theme) {
            gridRef.current.setTheme(theme);
        }
    }, [theme]);

    const getData = () => {
        if (gridRef.current) {
            gridRef.current.dataSource([]);
        }

        const param = {
            "operationName": "Countries",
            "variables": {},
            "query": commonUtil.normalize(CommonUtil.ALL_COUNTRIES)
        };

        context.ajax.post(CommonUtil.COUNTRIES_URL, param, null)
            .then((response: any) => {
                if (gridRef.current && response && response.data && response.data.countries) {
                    gridRef.current.dataSource(response.data.countries);
                }
            })
            .catch((err: any) => {
                console.error(err); // Handle the error accordingly
            });
    };

    const expandHandler = () => {
        if (gridRef.current) {
            gridRef.current.expandAll();
        }
    };

    const collapseHandler = () => {
        if (gridRef.current) {
            gridRef.current.collapseAll();
        }
    };

    const handleGroupByChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const newGroupBy = event.target.value;
        if (newGroupBy && newGroupBy !== "-1" && gridRef.current) {
            gridRef.current.groupBy(newGroupBy);
        }
        setGroupBy(newGroupBy);
    };

    const customClass: INSGridCustomClassSetting = {
        headerCell: "columnClass",
        nonFirstBodyColumn: "columnClass"
    };

    const gridColumn: INSGridColumn[] = [
        { headerText: "Currency", dataField: "currency", width: "10%", enableFilter: false, sortable: false },
        { headerText: "Country", dataField: "name", width: "30%", filter: { advancedFilterPopUpPos: "bottom-right" } },
        { headerText: "Native", dataField: "native", width: "20%", truncateToFit: true },
        { headerText: "Capital", dataField: "capital", width: "30%", truncateToFit: true },
        { headerText: "Phone Number", dataField: "phone", width: "15%" },
        { headerText: "Continent", dataField: "continent.name", width: "20%" }
    ];

    const gridSetting: INSGridReactSettings = {
        type: NSGridReact.GRID_TYPE_GROUP,
        groupByField: "currency",
        columnResizable: true,
        columnSortable: true,
        columnDraggable: false,
        enableFilter: true,
        enableAdvancedFilter: true,
        columns: gridColumn,
        customClass: customClass,
        rowKeyField: "code",
        heightOffset: 170,
        enableVirtualScroll: true,
        theme: theme || null,
    };

    const buttonStyle = { marginRight: "10px" };

    return (
        <div className="box box-ns-red">
            <div className="box-header">
                <h3 className="box-title">Grouping Grid Demo</h3>
                <div className="pull-right box-tools">
                    <select
                        id="cmbGroupBy"
                        className="select"
                        value={groupBy}
                        onChange={handleGroupByChange}
                    >
                        <option key="-1" value="-1">Select GroupBy</option>
                        {arrGroupBy.map((item, key) => (
                            <option key={key} value={item.value}>{item.name}</option>
                        ))}
                    </select>&nbsp;&nbsp;&nbsp;
                    <button className="btn btn-ns-grey btn-sm" onClick={expandHandler}>
                        <i className='fa fa-expand'></i>&nbsp;&nbsp;&nbsp;Expand
                    </button>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
                    <button className="btn btn-ns-grey btn-sm" onClick={collapseHandler}>
                        <i className='fa fa-compress text-white'></i>&nbsp;&nbsp;&nbsp;Collapse
                    </button>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
                </div>
            </div>
            <div className="box-body">
                <NSGridReact
                    setting={gridSetting}
                    dataSource={[]}
                    ref={gridRef}
                    style={{ width: "100%" }}
                />
            </div>
        </div>
    );
};

export default GroupingGridDemo;