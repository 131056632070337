import { createContext, useContext, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const NavigationContext = createContext(null);

export const NavigationProvider = (props: any) => {
    const navigate = useNavigate();
    const [navigation, setNavigation] = useState(() => navigate);

    const providerValue: any = useMemo(() => ({ navigate: navigation, setNavigate: setNavigation }), [navigation]);

    return (
        <NavigationContext.Provider value={providerValue}>
            {props.children}
        </NavigationContext.Provider>
    );
};

export const useAppNavigation = () => {
    const context: any = useContext(NavigationContext);
    if (!context) {
        throw new Error(`useAppNavigation must be used within a NavigationProvider`);
    }
    return context.navigate;
};