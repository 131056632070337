import React, { useState, useEffect, useRef } from "react";
import {  INSGridReactSettings } from "nscomponentsreact";
//import { NSGridReact } from "../../app/nsGrid/nsGridReact";
import {
    INSGridColumn,
    INSGridCustomClassSetting,
    INSGridEditorSetting,
    INSGridColumnEditorSetting,
    NSGridReact
} from 'nscomponentsreact';
import { CommonUtil } from '../../util/commonUtil';
import { useGlobalContext } from "../../app/globalContext";
import { useTheme } from "../../theme/themeContext";
import { TextBoxEditor } from "./components/textBoxEditor";

const FlatGridDemo: React.FC = () => {
    const context = useGlobalContext();
    const { theme } = useTheme();

    const gridRef = useRef<NSGridReact | null>(null);
    const commonUtil = new CommonUtil();

    useEffect(() => {
        getData();
    }, []);

    useEffect(() => {
        if (gridRef.current && theme) {
            gridRef.current.setTheme(theme);
        }
    }, [theme]);

    const getData = () => {
        if (gridRef.current) {
            gridRef.current.dataSource([]);
        }

        const param = {
            operationName: "Countries",
            variables: {},
            query: commonUtil.normalize(CommonUtil.ALL_COUNTRIES),
        };

        context.ajax.post(CommonUtil.COUNTRIES_URL, param, null)
            .then((response: any) => {
                if (gridRef.current && response?.data?.countries) {
                    //gridRef.current.dataSource(response.data.countries);
                    gridRef.current.dataSource(response.data.countries);
                }
            })
            .catch((err: any) => {
                console.error(err); 
            });
    };

    const editItemRenderer = (item: any, dataField: string, rowIndex: number, columnIndex: number, row: any) => {
        const span = document.createElement("span");

        if (item) {
            if (typeof item.isEdit === "undefined" || !item.isEdit) {
                const editIcon = document.createElement("i");
                editIcon.setAttribute("class", "fa fa-pencil-square-o");
                editIcon.setAttribute("title", "Click here to Edit");
                editIcon.addEventListener("click", () => {
                    item.isEdit = true;
                    gridRef.current?.updateItemInDataSource(item);
                    gridRef.current?.updateCellByKeyField(item["code"], dataField);
                    gridRef.current?.editByItem(item);
                });
                span.appendChild(editIcon);
            } else {
                const cancelIcon = document.createElement("i");
                cancelIcon.setAttribute("class", "fa fa-undo");
                cancelIcon.setAttribute("title", "Click here to Cancel");
                cancelIcon.style.marginRight = "10px";
                cancelIcon.addEventListener("click", () => {
                    item.isEdit = false;
                    gridRef.current?.updateItemInDataSource(item);
                    gridRef.current?.updateCellByKeyField(item["code"], dataField);
                    gridRef.current?.editStopByItem(item, '', true);
                });
                span.appendChild(cancelIcon);

                const saveIcon = document.createElement("i");
                saveIcon.setAttribute("class", "fa fa-save");
                saveIcon.setAttribute("title", "Click here to Save");
                saveIcon.addEventListener("click", () => {
                    item.isEdit = false;
                    gridRef.current?.updateItemInDataSource(item);
                    gridRef.current?.updateCellByKeyField(item["code"], dataField);
                    gridRef.current?.editStopByItem(item, '', false);
                });
                span.appendChild(saveIcon);
            }
        }

        return span;
    };

    const customClass: INSGridCustomClassSetting = {
        headerCell: "columnClass",
        nonFirstBodyColumn: "columnClass",
    };

    const textEditorSetting: INSGridColumnEditorSetting = {
        type: NSGridReact.EDITOR_TYPE_CUSTOM,
        customEditor: TextBoxEditor,
    };

    const gridColumn: INSGridColumn[] = [
        {
            headerText: "",
            dataField: "isEdit",
            width: "5%",
            draggable: false,
            sortable: false,
            sortDescending: false,
            itemRenderer: editItemRenderer,
            isExportable: false,
            showMenu: false,
            enableFilter: false,
            filter: { enableAdvancedFilter: false },
            enableEditable: false,
        },
        { headerText: "Country", dataField: "name", width: "15%", filter: { advancedFilterPopUpPos: "bottom-right" }, enableEditable: false },
        { headerText: "Native", dataField: "native", width: "15%", truncateToFit: true, enableEditable: false },
        { headerText: "Capital", dataField: "capital", width: "15%", truncateToFit: true, enableEditable: false },
        { headerText: "Phone Number", dataField: "phone", width: "15%", enableEditable: false },
        { headerText: "Continent", dataField: "continent.name", width: "15%", enableEditable: false },
        { headerText: "Currency", dataField: "currency", width: "20%", enableFilter: false, sortable: false, editorSetting: textEditorSetting },
    ];

    const editorSetting: INSGridEditorSetting = {
        editType: NSGridReact.EDITOR_EDITTYPE_ROW,
        clickType: NSGridReact.EDITOR_EDITING_NOCLICK,
        enableMultipleEdit: false,
    };

    const gridSetting: INSGridReactSettings = {
        type: NSGridReact.GRID_TYPE_NORMAL,
        columnResizable: true,
        columnSortable: true,
        columnDraggable: false,
        enableFilter: true,
        enableAdvancedFilter: true,
        enableEditable: true,
        columns: gridColumn,
        customClass: customClass,
        rowKeyField: "code",
        heightOffset: 170,
        enableVirtualScroll: true,
        theme: theme || null,
        editorSetting: editorSetting,
    };

    return (
        <div className="box box-ns-red">
            <div className="box-header">
                <h3 className="box-title">Flat Grid Demo</h3>
            </div>
            <div className="box-body">
                <NSGridReact setting={gridSetting} dataSource={[]} ref={gridRef} style={{ width: "100%" }} />
            </div>
        </div>
    );
};

export default FlatGridDemo;