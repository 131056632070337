import React, { useRef, useState, useEffect } from 'react';
import { NSCalendarReact, INSCalendarReactSetting } from 'nscomponentsreact';
import { useTheme } from '../../theme/themeContext';

interface CalendarProps {
  // Define props here if necessary
}

export const Calendar: React.FC<CalendarProps> = () => {

    const { theme, oldTheme } = useTheme();

    const calSimple = useRef<NSCalendarReact>(null);
    const calMinMax = useRef<NSCalendarReact>(null);
    const calDisabled = useRef<NSCalendarReact>(null);
    const calFooter = useRef<NSCalendarReact>(null);
    

    const themeChanged = () => {
        if(theme !== oldTheme) {
            if(calSimple.current) {
                calSimple.current.setTheme(theme);
            }
            if(calMinMax.current) {
                calMinMax.current.setTheme(theme);
            }
            if(calDisabled.current) {
                calDisabled.current.setTheme(theme);
            }
            if(calFooter.current) {
                calFooter.current.setTheme(theme);
            }
        }
    };

    const dateSelected = (type: string, destID: string, event: any) => {
        const div = document.getElementById(destID);
        if (div) {
            div.innerHTML = event.detail;
        }
    };

    themeChanged();

    const cellStyle: React.CSSProperties = { display: "inline-block", width: "50%" };
    const rowStyle: React.CSSProperties = { marginLeft: "100px" };

    return (
        <div className="box box-ns-red">
            <div className="box-header">
                <h3 className="box-title">Calendar Demo</h3>
            </div>
            <div className="box-body">
                <div className="row" style={rowStyle}>
                    <div style={cellStyle}>
                        <p> Basic Calendar</p>
                        <NSCalendarReact ref={calSimple} onDateSelected={(event: any) => dateSelected("simple", "divSimpleCalSelectedDate", event)}>
                        </NSCalendarReact>
                        <div id="divSimpleCalSelectedDate" style={{ height: "60px" }}>&nbsp;</div>
                    </div>
                    <div style={cellStyle}>
                        <p>Calendar with Min and Max date</p>
                        <NSCalendarReact ref={calMinMax} onDateSelected={(event: any) => dateSelected("minmax", "divCalMinMaxSelectedDate", event)}>
                        </NSCalendarReact>
                        <div id="divCalMinMaxSelectedDate" style={{ height: "60px" }}>&nbsp;</div>
                    </div>
                </div>
                <div className="row" style={rowStyle}>
                    <div style={cellStyle}>
                        <p>Calendar with Disabled Date (date less than today is disabled)</p>
                        <NSCalendarReact ref={calDisabled} onDateSelected={(event: any) => dateSelected("disabled", "divCalDisabledSelectedDate", event)}>
                        </NSCalendarReact>
                        <div id="divCalDisabledSelectedDate" style={{ height: "60px" }}>&nbsp;</div>
                    </div>
                    <div style={cellStyle}>
                        <p>Calendar With Footer</p>
                        <NSCalendarReact ref={calFooter} onDateSelected={(event: any) => dateSelected("footer", "divFooterCalSelectedDate", event)}>
                        </NSCalendarReact>
                        <div id="divFooterCalSelectedDate" style={{ height: "60px" }}>&nbsp;</div>
                    </div>
                </div>
            </div>
        </div>
    );
};
