
import React, { useEffect, useRef } from 'react';

import {
    INSGridColumn,
    INSGridCustomClassSetting,
    INSGridMasterDetailSetting,
    INSGridSetting,
    NSGridReact
} from 'nscomponentsreact';
//import { NSGridReact } from "../../app/nsGrid/nsGridReact";
//import { INSGridColumn, INSGridCustomClassSetting, INSGridEditorSetting, INSGridMasterDetailSetting, INSGridSetting } from "../../app/nsGrid/interfaces";
import { DetailGridRenderer } from './components/detailGridRenderer';
import { useTheme } from '../../theme/themeContext';


interface Props {
    
}

export const MasterDetailCustomGridDemo: React.FC<Props> = (props: Props) => {
    const { theme } = useTheme();
    const gridRef = useRef<NSGridReact | null>(null);

    useEffect(() => {
        getData();
    }, []);

    /*useEffect(() => {
        if (gridRef.current && theme) {
            gridRef.current.setTheme(theme);
        }
    }, [theme]);*/

    const getData = () => {
        let rowCount = 0;
        const totalRecords = 500;
        let arrItem: any = [];
        let hasLastCountryUS: boolean = false;
        for (let i = 0; i < totalRecords; i++){
            const item: any = {id: rowCount, hierarchy: 'hierarchy ' + (rowCount+1).toString(), supervisor: null, country: 'US', employees: "Employee" + i, price: '10.90', year: '1985',date:new Date(2018, 11, i + 1),checked:true};
            item.hierarchy = 'hie ' + (rowCount + 1).toString();
            item.hasChildren = ((i % 2) !== 0);
            if(item.hasChildren) {
                if(hasLastCountryUS) {
                    item.country = 'UK';
                }
                hasLastCountryUS = !hasLastCountryUS;
            }
            arrItem.push(item);
            rowCount++;
        }
        gridRef.current?.dataSource(arrItem);
    };

    const dateLabelFunction = (item: any,dataField: string,colItem: INSGridColumn) => {
        if(item && item[dataField])
        {
            var date = item[dataField];
            return ((date.getMonth() + 1) + '/' + date.getDate() + '/' +  date.getFullYear());
        }
        return "";
    }

    const gridDetailColumn: INSGridColumn[] = [
        {headerText:"Id",dataField:"id",width:"20%"},
        {headerText:"Country",dataField:"country",width:"15%"},
        {headerText:"Hierarchy",dataField:"hierarchy",width:"20%",headerTruncateToFit:true,truncateToFit:true},
        {headerText:"Year",dataField:"year",width:"20%"},
        {headerText:"Employees",dataField:"employees",width:"20%"},
        {headerText:"Date",dataField:"date",width:"20%",labelFunction: dateLabelFunction}
    ];
    const customClass: INSGridCustomClassSetting = {headerCell:"columnClass",nonFirstBodyColumn:"columnClass"};
    var masterDetailSetting: INSGridMasterDetailSetting = {hasChildField:"hasChildren",detailRenderer: DetailGridRenderer,detailRendererParam: {label:"Testing Code"},
    detailHeight: 200};
    const gridSetting: INSGridSetting = {type: NSGridReact.GRID_TYPE_MASTER_DETAIL, masterDetailSetting: masterDetailSetting,enableVirtualScroll:true,
        enableColumnSetting:true,enableFilter:true,enableAdvancedFilter:true,rowKeyField:"id",enableVariableRowHeight:true,enableRowSelection: false,
       enableExport:true,heightOffset:170,columns: gridDetailColumn, customClass: customClass};
    return (
        <div className="box box-ns-red">
              <div className="box-header">
                    <h3 className="box-title">Master Detail Grid Demo</h3>
              </div>
              <div className="box-body">
                    <NSGridReact setting={gridSetting} ref={gridRef} containerStyle={{"width": "100%"}}>
                    </NSGridReact>
               </div>
        </div>
    );
};

