import React, { useState, useEffect } from 'react';

import { NSExpressionEvaluator, NSExpressionEvaluatorType } from 'nscomponentsreact';
import { useTheme } from '../../theme/themeContext';

export const ExpressionEvaluator: React.FC = () => {
    const { theme, oldTheme } = useTheme();
    const [expression, setExpression] = useState<string>("persons[0].person1.age > 20 && gender === 'M'");
    const [model, setModel] = useState<string>('{"age": 20,"gender": "M", "persons": [{"person1": {"age": 21},"gender": "M"}]}');
    const [result, setResult] = useState<string>('');
    const [evaluator, setEvaluator] = useState<NSExpressionEvaluatorType | null>(null);
    const [throwErrorForUndefined, setThrowErrorForUndefined] = useState<boolean>(true);

    useEffect(() => {
        setEvaluator(new NSExpressionEvaluator({ throwErrorForUndefined }));
    }, [throwErrorForUndefined]);

    const handleExpressionChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setExpression(event.target.value);
    };

    const handleModelChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setModel(event.target.value);
    };

    const handleThrowErrorChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setThrowErrorForUndefined(event.target.checked);
    };

    const resultHandler = () => {
        if (!expression) {
            alert('Please enter an expression');
            return;
        }
        let jsonData: any;
        try {
            jsonData = toValidJSON(model);
            jsonData = JSON.parse(jsonData);
            jsonData.getData = (age: number, gender: string) => {
                console.log("In getData ", age, gender);
                return `Age is ${age} for Gender ${gender}`;
            };
        } catch (e) {
            console.error(e);
            alert('Please enter a valid JSON Model/Data');
            return;
        }

        try {
            if (evaluator) {
                const evalResult = evaluator.evaluate(expression, jsonData);
                console.log(evalResult);
                setResult('' + evalResult);
            }
        } catch (e) {
            console.error(e);
            alert('Error Occured::' + e);
            return;
        }
    };

    const toValidJSON = (str: string): string => {
        const processedStr = str.replace(/(\w+)(?=:)/g, '"$1"');
        console.log(processedStr);
        return processedStr;
    };

    const themeChanged = () => {
        if(theme !== oldTheme) {
        }
    };

    themeChanged();

    return (
        <div style={{ margin: '50px', marginTop: '20px' }}>
            <header>
                <h1 style={{ paddingLeft: '28vw' }}>NS Expression Evaluator Demo</h1>
            </header>
            <div>
                <div>
                    <label>
                        <input type="checkbox" checked={throwErrorForUndefined} onChange={handleThrowErrorChange} />
                        Throw Error For Undefined Variables
                    </label>
                </div>
                <h2>Expression:</h2>
                <textarea id="expression" className="textarea text" value={expression} onChange={handleExpressionChange} style={{ height: '200px', width: '100%', display: 'block' }} />

                <h2>Model / Data:</h2>
                <textarea id="model" className="textarea text" value={model} onChange={handleModelChange} style={{ height: '200px', width: '100%', display: 'block', margin: '0 0 10px' }} />

                <button id="btnResult" onClick={resultHandler}>Result</button>

                <h2>Output:</h2>
                <pre className="text">{result}</pre>
            </div>
        </div>
    );
};