import React, { useState, useEffect, useRef, useCallback } from 'react';
import { NSDashboardReact, INSDashboardReactSettings, INSDashboardPanelSetting, INSPanelToolBarDetails } from 'nscomponentsreact';
import { AnimalComponent } from './components/animalComponent';

export const Dashboard: React.FC<any> = (props) => {
  const [theme, setTheme] = useState(null);
  const [setting, setSetting] = useState<INSDashboardReactSettings>(getSetting());
  const dashboardRef = useRef<NSDashboardReact>(null);

  useEffect(() => {
    // Any componentDidMount logic can go here
  }, []);

  const themeChanged = useCallback((event: any) => {
    setTheme(event.details.selectedTheme);
  }, []);

  function getSetting(): INSDashboardReactSettings {
    const toolBarDetails: INSPanelToolBarDetails = {
      minimize: { iconHTML: null, title: "Minimize" },
      maximize: { iconHTML: null, title: "Maximize" },
      expand: { iconHTML: null, title: "Expand" },
      collapse: { iconHTML: null, title: "Collapse" },
      fullScreen: { iconHTML: "<i class='icon-fullscreen icon-1' style='pointer-events: none;'></i>", title: "Click here for Full Screen" },
      restore: { iconHTML: "<i class='fa fa-window-restore fa-1'></i>", title: "Click here to Restore" },
      close: { iconHTML: null, title: "Close" }
    };
    
    const setting: INSDashboardPanelSetting = {
      title: "Demo",
      titleHtml: "<i class='icon-move icon-1'></i>&nbsp;&nbsp;Demo",
      minWidth: 300,
      minHeight: 300,
      enablePopUp: false,
      enableModal: false,
      enableCollapse: true,
      enableMinimization: true,
      enableFullScreen: true,
      enableDrag: false,
      enableResize: true,
      enableTitleDblClick: false,
      enableMoveOnClick: false,
      customClass: {
        container: "panel",
        titleBar: "panelTitleBar",
        titleBarContent: "panelTitleBarContent",
        iconConatiner: null,
        icon: null,
        body: null
      },
      bodyComponent: AnimalComponent
    };

    const panelCount = 6;
    const arrSetting: INSDashboardPanelSetting[] = [];
    const objAnimal: any = { "0": { name: "dog" }, "1": { name: "cat" }, "2": { name: "goat" }, "3": { name: "sheep" }, "4": { name: "horse" }, "5": { name: "none" } };
    
    for (let count = 0; count < panelCount; count++) {
      arrSetting[count] = { ...setting };
      arrSetting[count].title = "Demo" + (count + 1);
      arrSetting[count].titleHtml = "<i class='icon-move icon-1'></i>&nbsp;&nbsp;Demo&nbsp;" + (count + 1);
      arrSetting[count].contentComponent = AnimalComponent;
      arrSetting[count].contentComponentData = objAnimal[count];
    }

    const dashBoardSetting: INSDashboardReactSettings = {
      panelDragClass: "drag",
      panelCount: panelCount,
      panelPerRow: 3,
      arrPanelSetting: arrSetting
    };

    return dashBoardSetting;
  }

  return (
    <div className="box box-ns-red">
      <div className="box-header">
        <h3 className="box-title">Dashboard Demo</h3>
      </div>
      <div className="box-body">
        <NSDashboardReact 
          setting={setting} 
          ref={dashboardRef} 
          className="dashboardDemo" 
          style={{ width: "100%", height: "100%" }} 
        />
      </div>
    </div>
  );
};