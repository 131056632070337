import React, { useState, useEffect, useRef } from "react";
import { NSGridReact, INSGridReactSettings } from "nscomponentsreact";
import { INSGridColumn, INSGridCustomClassSetting, INSGridEditorSetting } from "nscomponentsreact";
//import { INSGridReactSettings, NSGridReact } from "../../app/nsGrid/nsGridReact";
//import { INSGridColumn, INSGridCustomClassSetting, INSGridEditorSetting } from "../../app/nsGrid/interfaces";
import PetComponentRenderer from "./components/petComponentRenderer";
import { DataGenerator } from "../../util/dataGenerator";
import { CountryRenderer } from "./components/countryRenderer";
import { useTheme } from "../../theme/themeContext";

const HierarchicalGridDemo: React.FC = () => {
    const { theme } = useTheme(); 
    
    const gridRef = useRef<NSGridReact | null>(null);
    const dataGenerator = new DataGenerator();
    //const [theme, setTheme] = useState<string | null>(null);
    //const [gridSetting, setGridSetting] = useState<INSGridReactSettings | null>(null);

    useEffect(() => {
        getData();
    }, []);

    useEffect(() => {
        
        if (gridRef.current && theme) {
            gridRef.current.setTheme(theme);
        }
        //getData();
    }, [theme]);

    const getData = () => {
        const arrData = dataGenerator.getHierarchicalData(1000);
        if (gridRef.current) {
            gridRef.current.dataSource(arrData);
        }
    };

    /*const themeChanged = (event: any) => {
        setTheme(event.details.selectedTheme);
        if (gridRef.current) {
            gridRef.current.setTheme(event.details.selectedTheme);
        }
    };*/

    const expandHandler = () => {
        if (gridRef.current) {
            gridRef.current.expandAll();
        }
    };

    const collapseHandler = () => {
        if (gridRef.current) {
            gridRef.current.collapseAll();
        }
    };

    const customClass: INSGridCustomClassSetting = {
        headerCell: "columnClass",
        nonFirstBodyColumn: "columnClass",
    };

    const gridColumn: INSGridColumn[] = [
        { headerText: "Name", dataField: "name", width: "150px", filter: { advancedFilterPopUpPos: "bottom-right" } },
        { headerText: "Country", dataField: "country", width: "100px", truncateToFit: true, itemRendererComponent: CountryRenderer },
        { headerText: "Language", dataField: "language", width: "100px", truncateToFit: true },
        { headerText: "Asset", dataField: "asset", width: "80px" },
        { headerText: "Proficiency", dataField: "proficiency", width: "80px" },
        { headerText: "Phone", dataField: "phoneNumber", width: "100px" },
        {
            headerText: "Pet",
            dataField: "pet",
            width: "200px",
            enableFilter: false,
            sortable: false,
            itemRendererComponent: PetComponentRenderer,
            itemRendererComponentParam: { abc: "123", def: "456" },
        },
    ];

    const editorSetting: INSGridEditorSetting = {
        clickType: NSGridReact.EDITOR_EDITING_NOCLICK,
        enableMultipleEdit: true,
    };

    const gridSetting: INSGridReactSettings = {
        type: NSGridReact.GRID_TYPE_HIERARCHICAL,
        columnResizable: true,
        columnSortable: true,
        columnDraggable: false,
        enableFilter: true,
        enableAdvancedFilter: true,
        columns: gridColumn,
        customClass: customClass,
        rowKeyField: "employeeId",
        heightOffset: 170,
        enableVirtualScroll: true,
        theme: theme || undefined,
    };


    return (
        <div className="box box-ns-red">
            <div className="box-header">
                <h3 className="box-title">Hierarchical Grid Demo</h3>
                <div className="pull-right box-tools">
                    <button className="btn btn-ns-grey btn-sm" onClick={expandHandler}>
                        <i className="fa fa-expand"></i>&nbsp;&nbsp;&nbsp;Expand
                    </button>
                    &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
                    <button className="btn btn-ns-grey btn-sm" onClick={collapseHandler}>
                        <i className="fa fa-compress text-white"></i>&nbsp;&nbsp;&nbsp;Collapse
                    </button>
                    &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
                </div>
            </div>
            <div className="box-body">
                <NSGridReact
                    setting={gridSetting}
                    dataSource={[]}
                    ref={gridRef}
                    style={{ width: "100%" }}
                ></NSGridReact>
            </div>
        </div>
    );
};

export default HierarchicalGridDemo;