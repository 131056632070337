import React from 'react';

interface PageNotFoundProps {
    // Define any props here if necessary, currently assuming no props needed
}

export const PageNotFound: React.FC<PageNotFoundProps> = () => {
    return (
        <div className="box box-ns-red">
            <div className="box-header">
                <h3 className="box-title">Page Not Found</h3>
            </div>
            <div className="box-body">
                {/* Content can be added here if needed */}
            </div>
        </div>
    );
};
