import React, { useEffect, useRef, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { INSNavigationReactSettings, NSNavigationReact } from 'nscomponentsreact';//INSNavigationReactRef
import { useGlobalContext } from '../globalContext';
import { AppRoutes } from './appRoutes';
import { NavigationProvider } from './useAppNavigation';
import { useTheme } from '../../theme/themeContext';
//import { INSNavigationReactSettings, NSNavigationReact } from './nsNavigation/nsNavigationReact';

interface AuthenticateProps {
    initialize: boolean;
    toggleNavigation: boolean;
}

const Authenticate: React.FC<AuthenticateProps> = ({ initialize, toggleNavigation }) => {
    const context = useGlobalContext();
    const { theme, oldTheme } = useTheme();
    const dataSource: any[] = [
        { menuName: "Home", link: "/home", iconBeforeHtml: "<i class='fa fa-home' aria-hidden='true'></i>", iconAfterHtml: null, selected: true },
        { menuName: "Grid Demo", link: "#", iconBeforeHtml: "<i class='fa fa-table' aria-hidden='true'></i>", iconAfterHtml: null, expanded: true, childMenus: [
            { menuName: "Hierarchical", link: "/hierarchicalGrid", iconBeforeHtml: "<i class='fa fa-list-alt' aria-hidden='true'></i>", iconAfterHtml: null },
            { menuName: "Grouping", link: "/groupingGrid", iconBeforeHtml: "<i class='fa fa-users' aria-hidden='true'></i>", iconAfterHtml: null },
            { menuName: "Flat", link: "/flatGrid", iconBeforeHtml: "<i class='fa fa-table' aria-hidden='true'></i>", iconAfterHtml: null },
            { menuName: "Real Time", link: "/realTimeGrid", iconBeforeHtml: "<i class='fa fa-random' aria-hidden='true'></i>", iconAfterHtml: null },
            { menuName: "Master Detail", link: "/masterDetail", iconBeforeHtml: "<i class='fa fa-random' aria-hidden='true'></i>", iconAfterHtml: null }
        ]},
        { menuName: "Calendar Demo", link: "#", iconBeforeHtml: "<i class='fa fa-calendar' aria-hidden='true'></i>", iconAfterHtml: null, expanded: true, childMenus: [
            { menuName: "Calendar", link: "/calendar", iconBeforeHtml: "<i class='fa fa-calendar' aria-hidden='true'></i>", iconAfterHtml: null }
        ]},
        { menuName: "DatePicker Demo", link: "#", iconBeforeHtml: "<i class='fa fa-calendar' aria-hidden='true'></i>", iconAfterHtml: null, expanded: true, childMenus: [
            { menuName: "DatePicker", link: "/datePicker", iconBeforeHtml: "<i class='fa fa-calendar' aria-hidden='true'></i>", iconAfterHtml: null }
        ]},
        { menuName: "Panel Demo", link: "#", iconBeforeHtml: "<i class='fa fa-tachometer' aria-hidden='true'></i>", iconAfterHtml: null, expanded: true, childMenus: [
            { menuName: "Panel", link: "/panel", iconBeforeHtml: "<i class='fa fa-tachometer' aria-hidden='true'></i>", iconAfterHtml: null }
        ]},
        { menuName: "Dashboard Demo", link: "#", iconBeforeHtml: "<i class='fa fa-tachometer' aria-hidden='true'></i>", iconAfterHtml: null, expanded: true, childMenus: [
            { menuName: "Dashboard", link: "/dashboard", iconBeforeHtml: "<i class='fa fa-tachometer' aria-hidden='true'></i>", iconAfterHtml: null }
        ]},
        { menuName: "Autocomplete Demo", link: "#", iconBeforeHtml: "<i class='fa fa-tachometer' aria-hidden='true'></i>", iconAfterHtml: null, expanded: true, childMenus: [
            { menuName: "Autocomplete", link: "/autocomplete", iconBeforeHtml: "<i class='fa fa-tachometer' aria-hidden='true'></i>", iconAfterHtml: null }
        ]},
        { menuName: "Expression Evaluator Demo", link: "#", iconBeforeHtml: "<i class='fa fa-tachometer' aria-hidden='true'></i>", iconAfterHtml: null, expanded: true, childMenus: [
            { menuName: "Expression Evaluator", link: "/expressionEvaluator", iconBeforeHtml: "<i class='fa fa-tachometer' aria-hidden='true'></i>", iconAfterHtml: null }
        ]},
        { menuName: "MultiSelect Dropdown Demo", link: "#", iconBeforeHtml: "<i class='fa fa-tachometer' aria-hidden='true'></i>", iconAfterHtml: null, expanded: true, childMenus: [
            { menuName: "MultiSelect Dropdown", link: "/multiSelectDropdown", iconBeforeHtml: "<i class='fa fa-tachometer' aria-hidden='true'></i>", iconAfterHtml: null }
        ]}
    ];

    const [navSetting, setNavSetting] = useState<INSNavigationReactSettings>({
        header: "NAVIGATION",
        showCollapseIcon: true,
        iconCollapse: "<i class='fa fa-bars pull-right'></i>",
        titleField: "menuName",
        childField: "childMenus",
        iconPosition: "right",
        iconMenuExpanded: "<i class='fa fa-angle-down'></i>",
        iconMenuCollapsed: "<i class='fa fa-angle-down expanded'></i>",
        collapseTopOffset: -10,
        routeType: "history"
    });
    const [selectedMenu, setSelectedMenu] = useState<string>('');
    //const nsNavigationReactRef = useRef<INSNavigationReactRef>(null);
    const nsNavigationReactRef = useRef<any>(null);
    const divContentRef = useRef<HTMLDivElement>(null);
    // const navigate = useAppNavigation();

    useEffect(() => {
        if (initialize && nsNavigationReactRef.current) {
            nsNavigationReactRef.current.setDataSource(dataSource);
            nsNavigationReactRef.current.setTheme(theme);
            handleNavigationEvent(null, "navigationOpenStart");
            if(context.route) {
                setSelectedMenu(context.route);
                context.setProp({route: null});
            }
        }
    }, [initialize]);

    useEffect(() => {
        if (nsNavigationReactRef.current) {
            nsNavigationReactRef.current.toggleNavigation();
        }
    }, [toggleNavigation]);

    const themeChanged = () => {
        if (theme !== oldTheme) {
            const body = document.body;
            if (oldTheme) {
                body.classList.remove("nmPageBody" + oldTheme);
            }
            body.classList.add("nmPageBody" + theme);
            if (nsNavigationReactRef.current) {
                nsNavigationReactRef.current.setTheme(theme);
            }
        }
    };

    const handleNavigationEvent = (event: any, eventType: string) => {
        switch (eventType) {
            case "navigationOpenStart":
                if (divContentRef.current) {
                    divContentRef.current.style.marginLeft = "250px";
                }
                break;
            case "navigationCloseStart":
                if (divContentRef.current) {
                    divContentRef.current.style.marginLeft = "50px";
                }
                break;
            case "navigationMenuSelected":
                const item: any = event.detail;
                setSelectedMenu(item.link);
                // navigate(item.link);
                break;
            default:
                break;
        }
    };

    themeChanged();

    return (
        <React.Fragment>
            {context.isLoggedIn && !context.isAuthorized ? (
                <div className="box box-danger box-border screen-center-box">
                    <div className="box-header with-border">
                        <i className="fa fa-ban text-ns-red"></i>
                        <h3 className="box-title">Unauthorized</h3>
                    </div>
                    <div className="box-body">
                        You are not authorized to access this application.
                    </div>
                </div>
            ) : context.isLoggedIn && context.isAuthorized ? (
                <React.Fragment>
                     
                    <div id="divContent" className="nmPageContent" style={{ margin: "5px", height: "100%" }} ref={divContentRef}>
                        <BrowserRouter>
                            <NavigationProvider>
                                <NSNavigationReact
                                    containerStyle={{ marginTop: "50px", width: "250px" }}
                                    setting={navSetting}
                                    ref={nsNavigationReactRef}
                                    onNavigationOpenStart={(e: any) => handleNavigationEvent(e, "navigationOpenStart")}
                                    onNavigationCloseStart={(e: any) => handleNavigationEvent(e, "navigationCloseStart")}
                                    onNavigationMenuSelected={(e: any) => handleNavigationEvent(e, "navigationMenuSelected")}
                                />
                                <AppRoutes menuList={dataSource} selectedMenu={selectedMenu} />
                            </NavigationProvider>
                        </BrowserRouter>
                    </div>
                </React.Fragment>
            ) : (
                <div className="loader">
                    {/* Loader content here */}
                </div>
            )}
        </React.Fragment>
    );
};

export default Authenticate;